import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "kt_header",
  style: {},
  class: "header align-items-stretch"
}
const _hoisted_2 = {
  class: "d-flex align-items-center d-lg-none ms-n3 me-1",
  title: "Show aside menu"
}
const _hoisted_3 = {
  class: "btn btn-icon btn-active-light-secundary",
  id: "kt_aside_mobile_toggle"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2x mt-1 text" }
const _hoisted_5 = { class: "d-flex justify-content-sm-between w-100" }
const _hoisted_6 = {
  class: "d-flex align-items-center flex-grow-sm-1 w-100",
  id: "title"
}
const _hoisted_7 = { class: "fw-bolder fs-5 cor_texto_1 text-truncate text-concessionaria" }
const _hoisted_8 = {
  key: 1,
  class: "fw-bolder fs-5 cor_texto_1 text-truncate text-concessionaria"
}
const _hoisted_9 = {
  class: "d-flex align-items-center justify-content-end py-0 ms-3 p-2",
  id: "kt_header_menu_nav"
}
const _hoisted_10 = { class: "py-0 m-1" }
const _hoisted_11 = {
  class: "btn btn-sm btn-icon background_btn_1_opct",
  id: "kt_header_menu_mobile_toggle",
  style: {"height":"40px","width":"40px"}
}
const _hoisted_12 = { class: "svg-icon svg-icon-1 cor_btn_1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_KTMenu = _resolveComponent("KTMenu")!
  const _component_KTMenuDropdown = _resolveComponent("KTMenuDropdown")!
  const _component_router_view = _resolveComponent("router-view")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'container-fluid': _ctx.headerWidthFluid,
        'container-xxl': !_ctx.headerWidthFluid,
      }, "d-flex align-items-stretch justify-content-between"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "./media/icons/duotune/abstract/abs015.svg" })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (!_ctx.route.meta.hiddenHeader)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.isMobile)
                  ? (_openBlock(), _createBlock(_component_el_tooltip, {
                      key: 0,
                      class: "item",
                      effect: "dark",
                      placement: "bottom"
                    }, {
                      content: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.headerTitle), 1)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.headerTitle), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.headerTitle), 1))
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_KTMenu),
          _createVNode(_component_KTMenuDropdown),
          _createVNode(_component_router_view, {
            name: "filter",
            class: "m-1"
          }),
          _createVNode(_component_router_view, {
            name: "export",
            class: "m-1"
          }),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, [
                _createVNode(_component_inline_svg, { src: "./media/icons/duotune/text/txt001.svg" })
              ])
            ])
          ])
        ])
      ])
    ], 2)
  ], 512)), [
    [_vShow, _ctx.user]
  ])
}