
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AuthService from "@/services/AuthService";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const store = useStore();
  
    let user = AuthService.getUsuarioAuth()

    const signOut = () => {
      store.dispatch(Actions.LOGOUT);
    };

    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");

    function procuraAcesso (codNivel){
      // console.log("procuraAcesso", codNivel)
      if  ((user.codNivel) == -1){
        return "Administrador";
      };
       if ((user.codNivel) == 1){
        return distribuidor == "stellantis" ? "Gestor de checklist" : "Gerência de Pós-venda";
      };
      if ((user.codNivel) == 2){
        return "Consultor";
      };
      if ((user.codNivel) == 3){
        return "Orçamentista";
      };
      if ((user.codNivel) == 4){
        return "Controlador de quadro";
      };
      if ((user.codNivel) == 5){
        return "Visualizador de quadro";
      };
      if ((user.codNivel) == 6){
        return "Visualizador de histórico checklist";
      };
      
    };

    return {
      signOut,
      user,
      procuraAcesso
    };
  },
});
