
import { defineComponent, computed, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import AuthService from "@/services/AuthService";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
  showAsideLogo
} from "@/core/helpers/config";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTScrollTop,
    KTLoader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const userInfo = AuthService.getUsuarioAuth();
    const layoutQuadro = ref<boolean>(false)
    if (!store.getters.isUsuarioLogado) {
      //router.push({ name: "login" });
      return;
    }

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    store.dispatch(Actions.SET_CONCESSIONARIA_LIST);
    // initialize html element classes
    HtmlClass.init();
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      //check if current user is authenticated
      if (!store.getters.isUsuarioLogado) {
        // console.log('deslogado 116');
        router.push({ name: "login" });
        return;
      }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);

      if(layoutQuadro){
        const app = document.getElementById('app')
        if(app){
          app.style.backgroundColor = "var(--cor_background_2)"
        }
      }

      layoutQuadro.value = [4,5].includes(userInfo.codNivel)
   
     
    });   

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();
        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      layoutQuadro,
      showAsideLogo
    };
  },
});
