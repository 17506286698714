//pode ser que precise utilizar algumas das formas de texto

const nameTitleUser = {
    'stellantis' : 'Responsáveis'
} 

const nameTitleUserSingular = {
    'stellantis' : 'responsável'
}

export { nameTitleUser , nameTitleUserSingular }